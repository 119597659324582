import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import SaveIcon from "@material-ui/icons/Save";
import Select from "@material-ui/core/Select";
import { MenuItem, ListItemText } from "@material-ui/core";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import { i18n } from "../../translate/i18n";
import moment from "moment";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { ColorBox } from "material-ui-color";
import {
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import QueueOptions from "../QueueOptions";
import SchedulesForm from "../SchedulesForm";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  selectName: {
    marginRight: theme.spacing(1),
    flex: 1,
    width: "200px",
  },
  selectGreetingMessage: {
    marginRight: theme.spacing(1),
    flex: 1,
    width: "200px",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    maxHeight: "calc(100vh - 200px)",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  greetingMessage: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  custom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  placeholder: {
    color: theme.palette.grey[500],
    marginBottom: "-2px",
  },
  colorButton: {
    width: 20,
    height: 20,
    borderRadius: "50%",
  },
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t("queueModal.form.required")),
  specification: Yup.string()
    .max(50, i18n.t("textLength.long"))
    .required(i18n.t("queueModal.form.required")),
  color: Yup.string()
    .min(3, i18n.t("textLength.short"))
    .max(9, i18n.t("textLength.long"))
    .required(i18n.t("queueModal.form.required")),
  greetingMessage: Yup.string()
    .min(10, i18n.t("textLength.short"))
    .max(1000, i18n.t("textLength.long"))
    .nullable(),
  outOfHoursMessage: Yup.string().nullable(),
  type: Yup.string().required(i18n.t("queueModal.form.required")),
  webhookUrl: Yup.string().when("type", {
    is: "webhook",
    then: Yup.string()
      .matches(/^https:\/\/.+/, i18n.t("queueModal.form.invalidUrl"))
      .required(i18n.t("queueModal.form.required")),
    otherwise: Yup.string().nullable(),
  }),
  securityToken: Yup.string().when("type", {
    is: "webhook",
    then: Yup.string().required(i18n.t("queueModal.form.required")),
    otherwise: Yup.string().nullable(),
  }),
});

const QueueModal = ({ open, onClose, queueId, onEdit }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    specification: "",
    color: "",
    greetingMessage: null,
    chatbots: [],
    type: "simple",
    webhookUrl: null,
    securityToken: null,
    outOfHoursMessage: null,
  };

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [queue, setQueue] = useState(initialState);
  const [tab, setTab] = useState(0);
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);
  const greetingRef = useRef();
  const outOfHoursRef = useRef();

  const [activeStep, setActiveStep] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isStepContent, setIsStepContent] = useState(true);
  const [isOptionEdit, setIsOptionEdit] = useState(null);
  const [isNameEdit, setIsNameEdit] = useState(null);
  const [isGreetingMessageEdit, setGreetingMessageEdit] = useState(null);
  const [selectedGreetingMessage, setSelectedGreetingMessage] = useState("");

  const [schedules, setSchedules] = useState([
    {
      weekday: i18n.t("queueModal.weekdays.monday"),
      weekdayEn: "monday",
      startTime: "",
      endTime: "",
    },
    {
      weekday: i18n.t("queueModal.weekdays.tuesday"),
      weekdayEn: "tuesday",
      startTime: "",
      endTime: "",
    },
    {
      weekday: i18n.t("queueModal.weekdays.wednesday"),
      startTime: "",
      endTime: "",
    },
    {
      weekday: i18n.t("queueModal.weekdays.thursday"),
      weekdayEn: "thursday",
      startTime: "",
      endTime: "",
    },
    {
      weekday: i18n.t("queueModal.weekdays.friday"),
      weekdayEn: "friday",
      startTime: "",
      endTime: "",
    },
    {
      weekday: i18n.t("queueModal.weekdays.saturday"),
      weekdayEn: "saturday",
      startTime: "",
      endTime: "",
    },
    {
      weekday: i18n.t("queueModal.weekdays.sunday"),
      startTime: "",
      weekdayEn: "sunday",
      endTime: "",
    },
  ]);

  const [outOfHoursMessage, setOutOfHoursMessage] = useState("");

  const [queueOptions, setQueueOptions] = useState([]);

  const fetchQueueOptions = async () => {
    try {
      const { data } = await api.get("/queue");
      setQueueOptions(data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchQueueById = async (queueId) => {
    try {
      const { data } = await api.get(`/queue/${queueId}`);
      setQueue((prevState) => {
        return { ...prevState, ...data };
      });
      setSchedules(data.schedules);
      setOutOfHoursMessage(data.outOfHoursMessage);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    api.get(`/settings`).then(({ data }) => {
      if (Array.isArray(data)) {
        const scheduleType = data.find((d) => d.key === "scheduleType");
        if (scheduleType) {
          setSchedulesEnabled(scheduleType.value === "queue");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (queueId) {
      fetchQueueById(queueId);
    }
    return () => {
      setQueue(initialState);
    };
  }, [queueId, open]);

  useEffect(() => {
    if (activeStep === isNameEdit) {
      setIsStepContent(false);
    } else {
      setIsStepContent(true);
    }
  }, [isNameEdit, activeStep]);

  useEffect(() => {
    if (queue.type === "simple") {
      setQueue((prevState) => ({
        ...prevState,
        webhookUrl: null,
        securityToken: null,
      }));
    } else if (queue.type === "webhook") {
      setQueue((prevState) => ({
        ...prevState,
        greetingMessage: null,
        chatbots: [],
      }));
    }
  }, [queue.type]);

  const handleClose = () => {
    onClose();
    setIsOptionEdit(null);
    setIsNameEdit(null);
    setActiveStep(null);
    setGreetingMessageEdit(null);
    setOutOfHoursMessage(null);
    setTab(0);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (optionsId) => {
    try {
      await api.delete(`/queue/${optionsId}`);
      fetchQueueOptions();
      if (queueId) {
        await fetchQueueById(queueId);
      } else {
        setQueue(initialState);
      }
      setIsOptionEdit(null);
      setIsNameEdit(null);
      setGreetingMessageEdit(null);
      toast.success(i18n.t("queueModal.options.deleted"));
    } catch (err) {
      toastError(err);
    }
  };

const handleSaveQueue = async (values) => {
  try {
    let utcOffset = 0;
    const calculatedOffset = moment().utcOffset();
    if (!isNaN(calculatedOffset)) {
      utcOffset = calculatedOffset;
    }

    const requestBody = {
      ...values,
      schedules,
      outOfHoursMessage: values.outOfHoursMessage || null,
      greetingMessage: values.greetingMessage || null,
      webhookUrl: values.webhookUrl || null,
      securityToken: values.securityToken || null,
      utcOffset,
    };

    if (queueId) {
      await api.put(`/queue/${queueId}`, requestBody);
    } else {
      await api.post("/queue", requestBody);
    }

    toast.success(i18n.t("queueModal.success"));
    handleClose();
    fetchQueueOptions();
  } catch (err) {
    toastError(err);
  }
};

  const handleSaveSchedules = async (values) => {
    const { schedules: newSchedules, outOfHoursMessage: newOutOfHoursMessage } = values;
    toast.success(i18n.t("queueModal.options.clicToSave"));
    setSchedules(newSchedules);
    setOutOfHoursMessage(newOutOfHoursMessage);
    setTab(0);
  };

  const handleSaveBot = async (values) => {
    try {
      if (queueId) {
        await api.put(`/queue/${queueId}`, values);
      } else {
        await api.post("/queue", values);
      }

      toast.success(i18n.t("queueModal.options.saved"));
      await fetchQueueById(queueId);
      setIsOptionEdit(null);
      setIsNameEdit(null);
      setGreetingMessageEdit(null);
      setSelectedOption(null);
      setSelectedGreetingMessage(null);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    fetchQueueOptions();
  }, []);

  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (isNameEdit !== null && queue.chatbots[isNameEdit]) {
      const editedChatbot = queue.chatbots[isNameEdit];

      const queueOption = queueOptions.find(
        (queue) => queue.id === editedChatbot.isQueue
      );

      const selectedValue =
        editedChatbot.type === "queue"
          ? queueOption?.name
          : editedChatbot.name;

      const greetingMessageValue =
        editedChatbot.type === "queue"
          ? queueOption?.specification
          : editedChatbot.greetingMessage;

      setSelectedOption(selectedValue || "");
      setSelectedGreetingMessage(greetingMessageValue || "");
    } else {
      setSelectedOption("");
      setSelectedGreetingMessage("");
    }
  }, [isNameEdit, queue.chatbots, queueOptions]);

  const [selectOpen, setSelectOpen] = useState(false);
  const [colorFieldFocused, setColorFieldFocused] = useState(false);

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${i18n.t("queueModal.options.confirmationModal.deleteTitle")} ${selectedQueue.name}?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {i18n.t("queueModal.options.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        scroll="body"
      >
        <DialogTitle>
          {queueId
            ? `${i18n.t("queueModal.title.edit")}`
            : `${i18n.t("queueModal.title.add")}`}
        </DialogTitle>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => setTab(v)}
          aria-label="disabled tabs example"
        >
          <Tab label={i18n.t("queueModal.tab.queue")} />
          {schedulesEnabled && <Tab label={i18n.t("queueModal.tab.schedules")} />}
        </Tabs>
        {tab === 0 && (
          <Paper>
            <Formik
              initialValues={queue}
              validateOnChange={false}
              enableReinitialize={true}
              validationSchema={QueueSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveQueue(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({
                handleChange,
                touched,
                errors,
                isSubmitting,
                setFieldValue,
                values,
              }) => (
                <Form>
                  <DialogContent dividers className={classes.formControl}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={3}>
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.type")}
                          name="type"
                          select
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "simple") {
                              setFieldValue("webhookUrl", null);
                              setFieldValue("securityToken", null);
                            } else if (e.target.value === "webhook") {
                              setFieldValue("greetingMessage", null);
                              setFieldValue("chatbots", []);
                            }
                          }}
                        >
                          <MenuItem value="simple">
                            {i18n.t("queueModal.options.simple")}
                          </MenuItem>
                          <MenuItem value="webhook">
                            {i18n.t("queueModal.options.webhook")}
                          </MenuItem>
                        </Field>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.name")}
                          name="name"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.specification")}
                          name="specification"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                          error={touched.specification && Boolean(errors.specification)}
                          helperText={touched.specification && errors.specification}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.color")}
                          name="color"
                          id="color"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                          error={touched.color && Boolean(errors.color)}
                          helperText={touched.color && errors.color}
                          InputLabelProps={{
                            shrink: !!values.color || colorFieldFocused,
                          }}
                          onFocus={() => {
                            setColorFieldFocused(true);
                          }}
                          onBlur={() => setColorFieldFocused(false)}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                size="small"
                                style={{ backgroundColor: values.color }}
                                onClick={() => setColorPickerModalOpen(true)}
                              >
                                <Colorize className={classes.colorButton} />
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Dialog
                      open={colorPickerModalOpen}
                      onClose={() => setColorPickerModalOpen(false)}
                      aria-labelledby="color-picker-dialog-title"
                    >
                      <DialogTitle
                        id="color-picker-dialog-title"
                        disableTypography
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6">Seleccionar Color</Typography>
                        <IconButton
                          aria-label="close"
                          onClick={() => setColorPickerModalOpen(false)}
                          style={{
                            padding: 0,
                            color: "inherit",
                            fontSize: "0.875rem",
                            marginRight: "-12px",
                            marginTop: "-8px",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            },
                            "&:focus": {
                              boxShadow: "none",
                            },
                          }}
                        >
                          ✕
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <ColorBox
                          disableAlpha
                          value={values.color}
                          onChange={(val) => setFieldValue("color", `#${val.hex}`)}
                        />
                      </DialogContent>
                    </Dialog>
                    {values.type === "simple" && (
                      <div style={{ marginTop: 5 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              label={i18n.t("queueModal.form.greetingMessage")}
                              type="greetingMessage"
                              multiline
                              inputRef={greetingRef}
                              rows={5}
                              fullWidth
                              name="greetingMessage"
                              error={touched.greetingMessage && Boolean(errors.greetingMessage)}
                              helperText={touched.greetingMessage && errors.greetingMessage}
                              variant="outlined"
                              margin="dense"
                              InputLabelProps={{
                                shrink: !!values.greetingMessage,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {values.type === "webhook" && (
                      <div style={{ marginTop: 5 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={6}>
                            <Field
                              as={TextField}
                              label={i18n.t("queueModal.form.webhookUrl")}
                              name="webhookUrl"
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                              error={
                                touched.webhookUrl && Boolean(errors.webhookUrl)
                              }
                              helperText={touched.webhookUrl && errors.webhookUrl}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              as={TextField}
                              label={i18n.t("queueModal.form.securityToken")}
                              name="securityToken"
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                              error={
                                touched.securityToken &&
                                Boolean(errors.securityToken)
                              }
                              helperText={
                                touched.securityToken && errors.securityToken
                              }
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {values.type === "simple" && (
                      <div>
                        <FieldArray name="chatbots">
                          {({ push, remove }) => (
                            <>
                              <Typography
                                variant="subtitle1"
                                style={{ marginTop: "12px" }}
                              >
                                {i18n.t("queueModal.options.title")}
                              </Typography>
                              <Stepper
                                nonLinear
                                activeStep={activeStep}
                                orientation="vertical"
                              >
                                {values.chatbots &&
                                  values.chatbots.length > 0 &&
                                  values.chatbots.map((info, index) => (
                                    <Step
                                      key={`${info.id ? info.id : index}-chatbots`}
                                      onClick={() => setActiveStep(index)}
                                    >
                                      <StepLabel key={`${info.id}-chatbots`}>
                                        {isNameEdit !== index &&
                                        queue.chatbots[index]?.id ? ( //cambiado de name
                                          <div
                                            className={classes.greetingMessage}
                                            variant="body1"
                                          >
                                            {values.chatbots[index].type ===
                                            "queue"
                                              ? queueOptions.find(
                                                  (queue) =>
                                                    queue.id ===
                                                    values.chatbots[index]
                                                      .isQueue
                                                )?.name +
                                                " (" +
                                                i18n.t("queueModal.options.queue") +
                                                ")"
                                              : values.chatbots[index]?.name}
                                            <IconButton
                                              size="small"
                                              onClick={() => {
                                                setIsNameEdit(index);
                                                setIsStepContent(false);
                                              }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                            <IconButton
                                              onClick={() => {
                                                setSelectedQueue(info);
                                                setConfirmModalOpen(true);
                                              }}
                                              size="small"
                                            >
                                              <DeleteOutline />
                                            </IconButton>
                                          </div>
                                        ) : (
                                          <>
                                            {values.chatbots[index].type ===
                                            "queue" ? (
                                              <Field
                                                as={Select}
                                                variant="standard"
                                                color="primary"
                                                displayEmpty
                                                disabled={isSubmitting}
                                                error={
                                                  touched?.chatbots?.[index]
                                                    ?.name &&
                                                  Boolean(
                                                    errors.chatbots?.[index]
                                                      ?.name
                                                  )
                                                }
                                                className={classes.selectName}
                                                value={selectedOption}
                                                onChange={(e) => {
                                                  const selectedValue =
                                                    e.target.value;
                                                  setSelectedOption(
                                                    selectedValue
                                                  );
                                                  const selectedQueueOption =
                                                    queueOptions.find(
                                                      (queue) =>
                                                        queue.name ===
                                                        selectedValue
                                                    );
                                                  if (selectedQueueOption) {
                                                    setFieldValue(
                                                      `chatbots[${index}].isQueue`,
                                                      selectedQueueOption.id
                                                    );
                                                  }
                                                }}
                                                onOpen={() =>
                                                  setSelectOpen(true)
                                                }
                                                onClose={() =>
                                                  setSelectOpen(false)
                                                }
                                              >
                                                {!selectOpen && (
                                                  <MenuItem value="">
                                                    <ListItemText
                                                      primary={i18n.t(
                                                        "queueModal.options.option"
                                                      )}
                                                      className={
                                                        classes.placeholder
                                                      }
                                                    />
                                                  </MenuItem>
                                                )}
                                                {queueOptions
                                                  .filter(
                                                    (queue, currentIndex) => {
                                                      const currentName =
                                                        queue.name;
                                                      return (
                                                        currentIndex ===
                                                        queueOptions.findIndex(
                                                          (item) =>
                                                            item.name ===
                                                            currentName
                                                        )
                                                      );
                                                    }
                                                  )
                                                  .map((queue) => (
                                                    <MenuItem
                                                      key={queue.name}
                                                      value={queue.name}
                                                    >
                                                      {queue.name}
                                                    </MenuItem>
                                                  ))}
                                              </Field>
                                            ) : (
                                              <Field
                                                as={TextField}
                                                name={`chatbots[${index}].name`}
                                                variant="standard"
                                                color="primary"
                                                disabled={isSubmitting}
                                                error={
                                                  touched?.chatbots?.[index]
                                                    ?.name &&
                                                  Boolean(
                                                    errors.chatbots?.[index]
                                                      ?.name
                                                  )
                                                }
                                                className={classes.textField}
                                                placeholder={i18n.t(
                                                  "queueModal.options.option"
                                                )}
                                              />
                                            )}
                                            <Field
                                              as={Select}
                                              variant="standard"
                                              color="primary"
                                              name={`chatbots[${index}].type`}
                                              value={values.chatbots[index].type}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `chatbots[${index}].type`,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <MenuItem value="option">
                                                {i18n.t(
                                                  "queueModal.options.option"
                                                )}
                                              </MenuItem>
                                              <MenuItem value="queue">
                                                {i18n.t(
                                                  "queueModal.options.queue"
                                                )}
                                              </MenuItem>
                                            </Field>
                                            <IconButton
                                              size="small"
                                              onClick={() => {
                                                if (
                                                  (values.chatbots[index]
                                                    .type === "option" &&
                                                    values.chatbots[index]
                                                      .name &&
                                                    values.chatbots[index]
                                                      .greetingMessage) ||
                                                  (values.chatbots[index]
                                                    .type === "queue" &&
                                                    values.chatbots[index]
                                                      .isQueue)
                                                ) {
                                                  handleSaveBot(values);
                                                } else {
                                                  toast.error(
                                                    i18n.t(
                                                      "queueModal.options.fieldsRequired"
                                                    )
                                                  );
                                                }
                                              }}
                                              disabled={isSubmitting}
                                            >
                                              <SaveIcon />
                                            </IconButton>
                                            <IconButton
                                              size="small"
                                              onClick={() => {
                                                remove(index);
                                                setSelectedOption("");
                                                setSelectedGreetingMessage("");
                                              }}
                                              disabled={isSubmitting}
                                            >
                                              <DeleteOutline />
                                            </IconButton>
                                            <div className={classes.greetingMessage}>
                                              {values.chatbots[index].type ===
                                              "queue" ? (
                                                <Field
                                                  as={Select}
                                                  variant="standard"
                                                  color="primary"
                                                  displayEmpty
                                                  disabled={isSubmitting}
                                                  error={
                                                    touched?.chatbots?.[index]
                                                      ?.greetingMessage &&
                                                    Boolean(
                                                      errors?.chatbots?.[index]
                                                        ?.greetingMessage
                                                    )
                                                  }
                                                  className={
                                                    classes.selectName
                                                  }
                                                  value={
                                                    selectedGreetingMessage
                                                  }
                                                  onChange={(e) => {
                                                    const selectedValue =
                                                      e.target.value;
                                                    setSelectedGreetingMessage(
                                                      selectedValue
                                                    );
                                                    if (e.target.value) {
                                                      const selectedQueueOption =
                                                        queueOptions.find(
                                                          (queue) =>
                                                            queue.name ===
                                                              selectedOption &&
                                                            queue.specification ===
                                                              e.target.value
                                                        );
                                                      if (selectedQueueOption) {
                                                        setFieldValue(
                                                          `chatbots[${index}].isQueue`,
                                                          selectedQueueOption.id
                                                        );
                                                      }
                                                    }
                                                  }}
                                                  onOpen={() =>
                                                    setSelectOpen(true)
                                                  }
                                                  onClose={() =>
                                                    setSelectOpen(false)
                                                  }
                                                >
                                                  {!selectOpen && (
                                                    <MenuItem value="">
                                                      <ListItemText
                                                        primary={i18n.t(
                                                          "queueModal.options.specification"
                                                        )}
                                                        className={
                                                          classes.placeholder
                                                        }
                                                      />
                                                    </MenuItem>
                                                  )}
                                                  {queueOptions
                                                    .filter(
                                                      (queue) =>
                                                        queue.name ===
                                                        selectedOption
                                                    )
                                                    .map((queue) => (
                                                      <MenuItem
                                                        key={
                                                          queue.specification
                                                        }
                                                        value={
                                                          queue.specification
                                                        }
                                                      >
                                                        {queue.specification}
                                                      </MenuItem>
                                                    ))}
                                                </Field>
                                              ) : (
                                                <Field
                                                  as={TextField}
                                                  name={`chatbots[${index}].greetingMessage`}
                                                  variant="standard"
                                                  margin="dense"
                                                  fullWidth
                                                  multiline
                                                  error={
                                                    touched?.chatbots?.[index]
                                                      ?.greetingMessage &&
                                                    Boolean(
                                                      errors?.chatbots?.[index]
                                                        ?.greetingMessage
                                                    )
                                                  }
                                                  helperText={
                                                    touched?.chatbots?.[index]
                                                      ?.greetingMessage &&
                                                    errors?.chatbots?.[index]
                                                      ?.greetingMessage
                                                  }
                                                  className={
                                                    classes.textField
                                                  }
                                                  placeholder={i18n.t(
                                                    "queueModal.options.message"
                                                  )}
                                                />
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </StepLabel>
                                      {isStepContent &&
                                        queue.chatbots[index] && (
                                          <StepContent>
                                            {values.chatbots[index].type ===
                                            "queue" ? (
                                              <div
                                                className={classes.greetingMessage}
                                              >
                                                <Typography
                                                  color="textSecondary"
                                                  variant="body1"
                                                  style={{ fontSize: "0.7m" }}
                                                >
                                                  {i18n.t(
                                                    "queueModal.options.specification"
                                                  )}
                                                  :
                                                </Typography>
                                                &nbsp;
                                                {queueOptions.find(
                                                  (queue) =>
                                                    queue.id ===
                                                    values.chatbots[index]
                                                      .isQueue
                                                )?.specification}
                                              </div>
                                            ) : (
                                              <div
                                                className={classes.greetingMessage}
                                              >
                                                <Typography
                                                  color="textSecondary"
                                                  variant="body1"
                                                >
                                                  {i18n.t(
                                                    "queueModal.options.message"
                                                  )}
                                                  :
                                                </Typography>
                                                &nbsp;
                                                {values.chatbots[index]
                                                  .greetingMessage}
                                              </div>
                                            )}
                                            <QueueOptions chatBotId={info.id} />
                                          </StepContent>
                                        )}
                                    </Step>
                                  ))}
                              </Stepper>
                              {isNameEdit === null && (
                                <Button
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    push({
                                      type: "option",
                                    })
                                  }
                                  style={{
                                    width: 24,
                                    height: 24,
                                    minWidth: 0,
                                    padding: 0,
                                    marginLeft: 24,
                                    marginTop: 10,
                                    borderRadius: "50%",
                                    borderWidth: 3,
                                  }}
                                  variant="outlined"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AddIcon style={{ fontSize: 16 }} />
                                  </div>
                                </Button>
                              )}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {i18n.t("queueModal.buttons.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {queueId
                        ? `${i18n.t("queueModal.buttons.okEdit")}`
                        : `${i18n.t("queueModal.buttons.okAdd")}`}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Paper>
        )}
        {tab === 1 && (
          <Paper>
            <SchedulesForm
              loading={false}
              onSubmit={handleSaveSchedules}
              initialValues={{
                schedules: schedules,
                outOfHoursMessage: outOfHoursMessage,
              }}
            />
          </Paper>
        )}
      </Dialog>
    </div>
  );
};

export default QueueModal;
